/* Reset Overlay, Container, Title, Content(div) & Close button */
.pum-overlay,
.pum-container,
.pum-title,
.pum-content,
.pum-content + .pum-close,
.pum-content + .pum-close:hover,
.pum-content + .pum-close:focus,
.pum-content + .pum-close:active {
  background: none;
  border: none;
  bottom: auto;
  clear: none;
  cursor: default;
  /* didn't really know what the default for display should be*/
  /*display:inline;*/
  float: none;
  font-family: inherit;
  font-size: medium;
  font-style: normal;
  font-weight: normal;
  height: auto;
  left: auto;
  letter-spacing: normal;
  line-height: normal;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  overflow: visible;
  position: static;
  right: auto;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-transform: none;
  top: auto;
  visibility: visible;
  white-space: normal;
  width: auto;
  z-index: auto;
}

.pum-title,
.pum-content {
  position: relative;
  z-index: 1;
}

.pum-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1999999999;
  overflow: auto;
  overflow: initial;
  display: none;
  transition: all .15s ease-in-out;

  &.pum-preview,
  &.pum-form-submission-detected {
    display: block;
  }

  /**
  * Use border-box for all popup content. Providing more precise sizing.
  */
  &, &:before, &:after,
  & *, & *:before, & *:after {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
  }

}

.pum-container {
  top: 100px;
  position: absolute;
  margin-bottom: 3em;
  z-index: 1999999999;

  &.pum-responsive {

    left: 50%;
    margin-left: -47.5%;
    width: 95%;
    height: auto;
    overflow: visible;

    // Add Responsive Image Handling.
    img {
      max-width: 100%;
      height: auto;
    }

    @media only screen and (min-width: 1024px) {
      &.pum-responsive-nano {
        margin-left: -5%;
        width: 10%;
      }

      &.pum-responsive-micro {
        margin-left: -10%;
        width: 20%;
      }

      &.pum-responsive-tiny {
        margin-left: -15%;
        width: 30%;
      }

      &.pum-responsive-small {
        margin-left: -20%;
        width: 40%;
      }

      &.pum-responsive-medium {
        margin-left: -30%;
        width: 60%;
      }

      &.pum-responsive-normal {
        margin-left: -30%;
        width: 70%;
      }

      &.pum-responsive-large {
        margin-left: -35%;
        width: 80%;
      }

      &.pum-responsive-xlarge {
        margin-left: -47.5%;
        width: 95%;
      }

      &.pum-position-fixed {
        position: fixed;
      }
    }

    @media only screen and (max-width: 1024px) {
      &.pum-position-fixed {
        position: absolute;
      }
    }

  }

  &.custom-position {
    left: auto;
    top: auto;
    margin-left: inherit;
  }

  .pum-title {
  }

  .pum-content {

    > :last-child {
      margin-bottom: 0;
    }

    + .pum-close {
      text-decoration: none;
      text-align: center;
      line-height: 1;
      position: absolute;
      cursor: pointer;
      min-width: 1em;
      z-index: 2;

      > span {
        position: relative;
        z-index: 1;
      }
    }
  }

  &.pum-scrollable {

    .pum-content {

      overflow: auto;
      overflow-y: scroll;
      max-height: 95%;

    }

  }

}

&.pum-overlay-disabled {
  visibility: hidden;

  &::-webkit-scrollbar {
    display: block;
  }

  .pum-container {
    visibility: visible;
  }
}

html.pum-open {

  &.pum-open-overlay {
    overflow: hidden;

    &.pum-open-fixed {

      .pum-overlay {
        overflow: hidden;
      }

      .pum-container {
        position: fixed;
      }
    }

    &.pum-open-scrollable {

      body > *[aria-hidden] {
        padding-right: 15px;
      }

      .pum-overlay.pum-active {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }

    }

  }

  &.pum-open-overlay-disabled {

    &.pum-open-fixed {
      .pum-container {
        position: fixed;
      }
    }

    &.pum-open-scrollable {
      .pum-overlay.pum-active {
        position: static;
        height: auto;
        width: auto;
      }
    }

  }

}
